$(function(){
    $('.accordion-box__title').click(function () {
        var accordion_parent = $(this).closest('.accordion-box');
        var header_height = 140;

        // close other accordions
        // $('.accordion-box__title').not(this).removeClass('accordion-box__title--active');
        // $('.accordion-box').not(accordion_parent).removeClass('accordion-box--open');
        
        $(this).toggleClass('accordion-box__title--active');
        accordion_parent.toggleClass('accordion-box--open');

        // smooth scroll to open accordion
        // setTimeout(function(){
        //     $("body,html").animate({
        //         scrollTop: $('.accordion-box--open').offset().top - header_height
        //     }, 500);
        // }, 500);
    });
});
