$(function(){
    /* ==== Mobile navigation ==== */
    menu_trigger = $('.menu-trigger');
    nav = $('nav');

    menu_trigger.click(function () {
        $(this).toggleClass('menu-trigger--active');
        nav.toggleClass('nav--active');
    });

    $(document).click(function (e) {
        if ((! menu_trigger.is(e.target))) {
            menu_trigger.removeClass('menu-trigger--active');
            nav.removeClass('nav--active');
        }
    });

    $(document).scroll(function(){
        if ($(document).scrollTop() >= 40) {
            $('nav').addClass('nav--sticky');
        }
        else {
            $('nav').removeClass('nav--sticky');
        }
    });
})